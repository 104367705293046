.m-brand {
  &__area {
    display: flex;
    align-items: center;
    font-size: 0.8947rem;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }

  &__info {
    margin-left: 30px;
  }

  &__name {
    margin: 0;
    font: inherit;
    text-transform: none;
    letter-spacing: normal;
  }

  &__status {
    font-weight: 600;
  }
}
