.m-indicators {
  &--light {
    // default color is defined in theme.scss.liquid
    .m-indicators__btn {
      &[aria-expanded="true"], // support for Bootstrap tabs
      &.is-active {
        &:before {
          background-color: #fff;
        }
      }

      &:hover {
        &:before {
          background-color: #fff;
        }
      }
    }
  }

  &--vertical {
    min-width: 70px;

    li,
    .m-indicators__list-item {
      display: list-item;
      text-align: center;
    }
  }

  &__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    margin-left: -15px;
  }

  li, // same as .m-indicators__list-item (required by Splide pagination)
  &__list-item {
    display: inline-block;
  }

  &__btn {
    @extend %button-unstyled;
    padding: 15px;

    &[aria-expanded="true"], // support for Bootstrap tabs
    &.is-active {
      &:before {
        width: 22px;
        height: 22px;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      transition: background-color, width, height;
      transition-duration: $base-duration;
      text-indent: -999em;
      overflow: hidden;
  
      // Fix problem with non-clickable indicator as tab triggers
      .m-tabs__link > & {
        pointer-events: none;
      }
    }
  }
}
