.m-teasers {
  &__row {
    position: relative;
    overflow: hidden;

    + .m-teasers__row {
      margin-top: 50px;
    }
  }

  &__container {
    @extend .m-container-content;

    @media screen and (min-width: $screen-md-min) {
      display: flex;
    }

    &--inverse {
      align-items: center;

      .m-teasers__section {
        order: 1;
      }

      .m-teasers__section-content {
        order: 2;
      }

      .m-teasers__list {
        display: flex; // keep vertical navigation next to the content

        @media screen and (min-width: $screen-md-min) {
          padding-left: 2.5rem;
        }
      }

      .m-teasers__picture {
        right: 60%;

        @media screen and (min-width: $screen-lg-min) {
          right: 50%;
        }
      }
    }
  }

  &__section {
    @media screen and (min-width: $screen-md-min) {
      width: 40%;
    }

    @media screen and (min-width: $screen-lg-min) {
      width: 50%;
    }
  }

  &__section-content {
    @media screen and (min-width: $screen-md-min) {
      width: 60%;
    }

    @media screen and (min-width: $screen-lg-min) {
      width: 50%;
      //padding-bottom: 50px;
    }

    &--spacing {
      @media screen and (min-width: $screen-md-min) {
        margin-top: 50px;
      }
    }

    .m-fancy-header__title {
      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        letter-spacing: normal;
      }
    }
  }

  &__content {
    @media screen and (min-width: $screen-sm-min) {
      padding: 0 2.4rem 2.4rem;
    }

    &--spacing {
      margin-top: 2.4rem;
    }
  }

  &__list {
    @media screen and (min-width: $screen-sm-min) {
      display: flex;
    }
  }

  &__list-nav {
    @media screen and (min-width: $screen-sm-min) {
      width: 40%;
    }
  }

  &__item-title {
    margin: 0 0 2.4rem;
    text-transform: uppercase;
    font-size: 1.2632rem;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      word-wrap: break-word; /* simple fallback for `hyphens` */
      hyphens: auto;
    }

    @media screen and (min-width: $screen-xs-min) {
      letter-spacing: $behemot-letter-spacing;
    }

    @media screen and (min-width: $screen-sm-min) {
      font-size: 2.5263rem;
    }
  }

  &__item-description {
    margin: 2.4rem 0;
  }

  &__picture {
    display: none;
    width: 100%;

    @media screen and (min-width: $screen-md-min) {
      @include position(absolute, 50px null nul null);
      display: block;
      max-width: 50vw; // better CSS cropping
    }
  }

  &__image {
    box-shadow: $behemot-shadow;

    @media screen and (min-width: $screen-md-min) {
      @include object-fit;
    }
  }

  &__action {
    white-space: normal;

    .btn {
      white-space: inherit;
    }
  }

  &__action-group {
    text-align: center;

    @media screen and (min-width: $screen-sm-min) {
      display: inline-block;
      margin-left: 0;
    }
  }
}
