$m-section-zindex: $zindex-ui !default;

.m-section {
  position: relative;
  margin-bottom: 3.6842rem; // ~70px

  @media screen and (min-width: $screen-lg-min) {
    margin-bottom: 6.3158rem; // ~130px
  }

  &--connected-bottom {
    @media screen and (min-width: $screen-md-min) {
      // Breakpoint corresponding to breakpoint of the shifted `.m-testimonials__item--featured` element.
      margin-bottom: -6.3158rem; // ~130px
      z-index: $m-section-zindex;
    }
  }

  &--medium {
    @media screen and (min-width: $screen-md-min) {
      margin-bottom: 4.7268rem; // ~90px
    }
  }

  &--checkout {
    margin-bottom: 3.1579rem; // ~60px

    @media screen and (max-width: $screen-xs-min - 1) {
      display: none;
    }

    @media screen and (min-width: $screen-md-min) {
      margin-bottom: 4.7268rem; // ~90px
    }
  }

  &--small {
    margin-bottom: 3.1579rem; // ~60px
  }

  &--no-margin {
    margin-bottom: 0;
  }
}
