.m-hero {
  position: relative;
  overflow: hidden;

  &__list {
    @include list-unstyled;
    @include clearfix;
    margin: 0;
  }

  &__list-item {
    position: relative;
    width: 100%;
  }

  &__item-inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px floor($grid-gutter-width / 2) 100px;

    @media screen and (min-width: $screen-md-min) {
      padding: 80px $grid-gutter-width 140px;
    }

    &--spacing {
      padding-top: 100px;

      @media screen and (min-width: $screen-md-min) {
        padding-top: 160px;
      }
    }

    &--tight {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  &__arrows {
    display: none;
  }

  &__indicators {
    @include position(absolute, null 0 25px 0);
    text-align: center;

    @media screen and (min-width: $screen-md-min) {
      bottom: 45px
    }
  }

  &__slide-content {
    position: relative;
    text-align: center;
    color: #fff;
  }

  &__title {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    text-shadow: $behemot-shadow;

    @media screen and (max-width: $screen-xs-max) {
      word-wrap: break-word; /* simple fallback for `hyphens` */
      hyphens: auto;
    }

    @media screen and (min-width: $screen-xs-min) {
      letter-spacing: $behemot-letter-spacing;
      font-size: 2.5rem;
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 5rem;
      letter-spacing: 0.2em;
    }

    @media screen and (min-width: $screen-lg-min) {
      font-size: 7.5rem;
    }

    &--first-line {
      @media screen and (min-width: $screen-md-min) {
        font-size: 3rem;

        &:first-line {
          font-size: 5rem;
        }
      }

      @media screen and (min-width: $screen-lg-min) {
        font-size: 5rem;

        &:first-line {
          font-size: 7.5rem;
        }
      }
    }

    &--smaller {
      @media screen and (min-width: $screen-lg-min) {
        font-size: 5rem;
      }
    }
  }

  &__description {
    margin: 20px 0;

    @media screen and (min-width: $screen-lg-min) {
      font-size: 1.5789rem;
    }
  }

  &__action {
    margin-top: 30px;

    @media screen and (min-width: $screen-md-min) {
      margin-top: 60px;
    }

    .btn {
      white-space: normal;
      box-shadow: $behemot-shadow;
    }
  }

  &__info {
    font-size: 0.8947rem;
  }
}
