.m-nav-toggle {
  &__btn {
    border: 3px solid;

    .icon-reorder {
      &:before {
        vertical-align: baseline;
      }
    }
  }
}
