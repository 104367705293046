.m-header {
  &__header {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    @media screen and (min-width: $screen-lg-min) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  &__brand {
    .m-brand {
      &__logo {
        max-height: 60px;
        max-width: 30vw;

        @media screen and (min-width: $screen-md-min) {
          max-height: 80px;
        }

        @media screen and (min-width: $screen-lg-min) {
          max-height: 120px;
        }
      }

      &__info {
        @media screen and (max-width: $grid-float-breakpoint-max) {
          display: none;
        }
      }
    }
  }

  &__nav {
    font-size: 0.9473rem;
    order: 5;

    @media screen and (min-width: $screen-lg-min) {
      flex-grow: 1;
      order: 0;
    }

    .m-nav-toggle {
      @media screen and (min-width: $screen-lg-min) {
        display: none;
      }
    }

    .m-nav {
      text-align: center;

      @media screen and (max-width: $screen-md-max) {
        display: none;
      }
    }
  }

  &__info {
    flex-grow: 1;
    font-size: 0.9473rem;
    text-align: center;

    @media screen and (min-width: $screen-lg-min) {
      flex-grow: 0;
      text-align: right;
    }

    .m-icon-group__item-link {
      color: inherit;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
