.m-steps {
  &__list {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    flex-flow: row wrap;
    justify-content: space-between;
    counter-reset: behemot-steps-counter;
  }

  &__list-item {
    position: relative;
    margin-bottom: 3.1579rem; // ~60px
    flex: 1 0 260px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    margin: 2.5rem 0 3.1579rem;
    font-size: 1.4737rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 1.5789rem;
    }

    &:before {
      position: absolute;
      z-index: -1;
      font-size: 5rem;
      font-weight: $font-weight-bold;
      line-height: 0;
      margin-left: -70px;
      counter-increment: behemot-steps-counter;
      content: counter(behemot-steps-counter, decimal-leading-zero);

      @media screen and (min-width: $grid-float-breakpoint) {
        margin-left: -120px;
        font-size: 7rem;
      }
    }
  }

  &__item {
    padding-right: 15px;
    padding-left: 3.6842rem;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-left: 6rem;
    }
  }

  &__description {
    margin-bottom: 35px;
  }

  &__action-link {
    color: inherit;
    font-size: 116%;
    font-weight: $font-weight-bold;
  }
}

.m-section {
  &--steps {
    margin-bottom: 70px;
  }
}
