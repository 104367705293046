.m-testimonials {
  overflow: hidden;

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;

    &--featured {
      @media screen and (min-width: $screen-md-min) {
        flex-wrap: nowrap;
      }
    }

    @media screen and (min-width: $screen-lg-min) {
      margin-right: -60px;
      margin-left: -60px;
    }
  }

  &__item {
    position: relative;
    margin-top: 25px; // make sure there is enough space for box-shadow
    margin-bottom: 25px;
    padding: 0 floor($grid-gutter-width / 2);
    text-align: center;
    max-width: 640px;

    @media screen and (min-width: $screen-sm-min) {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
      flex: 1 1 33%;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-right: 60px;
      padding-left: 60px;
      padding-bottom: 80px;
      max-width: 33%;
      flex: 0 0 33%;
    }

    &--featured {
      order: 10;

      @media screen and (min-width: $screen-md-min) {
        // Breakpoint corresponding to breakpoint of the shifted `.m-section--connected-bottom` element.
        padding-top: 60px;
        padding-bottom: 90px;
        box-shadow: $behemot-shadow;
        order: 0;
      }
    }

    &--grid {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__indicator {
    margin-bottom: 35px;
  }

  &__header {
    position: relative;
    z-index: 2;
  }

  &__content {
    position: relative;
    margin-bottom: 35px;
    font-style: italic;
  }

  &__author {
    margin-bottom: 40px;
    font-weight: $font-weight-bold;
  }

  &__annotation-content {
    font-style: italic;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }

  &__footer {
    position: relative;
  }
}
