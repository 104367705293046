.m-footer {
  position: relative;
  overflow: hidden;

  &__container {
    @extend .m-container-special;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: flex;
    }
  }

  &__main {
    position: relative;

    @media screen and (max-width: $screen-xs-max) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    a {
      color: inherit;
    }

    .btn-default {
      border-color: currentColor;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 66%;
    }

    &:before {
      @include position(absolute, 0 -50% 0 null);
      z-index: -1;
      width: 200%;
      content: "";

      @media screen and (min-width: $grid-float-breakpoint) {
        right: 0;
        width: 999em;
      }
    }

    &--standalone {
      width: 100%;
      padding-top: 60px;
      padding-bottom: 60px;

      @media screen and (min-width: $screen-sm-min) {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }
  }

  &__aside {
    @media screen and (min-width: $grid-float-breakpoint) {
      width: 34%;
    }
  }

  &__main-cols {
    @media screen and (min-width: $screen-sm-min) {
      display: flex;
    }
  }

  &__address {
    padding-top: 60px;
    margin-bottom: 60px;
    flex: 1 1 0;

    @media screen and (min-width: $screen-sm-min) {
      padding-right: 50px;
      padding-top: 100px;
      margin-bottom: 100px;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-right: 150px;
    }
  }

  &__orders {
    margin-top: 60px;
    margin-bottom: 60px;
    flex: 1 1 0;

    @media screen and (min-width: $screen-sm-min) {
      margin-top: 100px;
      margin-bottom: 100px;
      width: 50%;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-right: 50px;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-right: 150px;
    }
  }

  &__copyright {
    margin: 0;
    padding-bottom: 60px;

    @media screen and (min-width: $screen-sm-min) {
      padding-bottom: 100px;
    }
  }

  &__brand {
    padding-top: 50px;

    @media screen and (min-width: $screen-xs-min) and (max-width: $grid-float-breakpoint-max) {
      display: flex;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-top: 100px;
      padding-bottom: 100px;
      padding-left: 50px;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-left: 150px;
    }
  }

  &__brand-spacing {
    margin: 0;

    @media screen and (min-width: $screen-xs-min) and (max-width: $grid-float-breakpoint-max) {
      margin-left: 50px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-left: 0;
    }
  }

  &__spacing {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__heading {
    margin: 0 0 50px;
    font-size: 1.4737rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 1.5789rem;
    }
  }

  &__info {
    display: block;
    margin: 30px 0;
    width: 100%;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    &--muted {
      opacity: 0.6;
    }
  }

  &__icon-group {
    margin-top: 20px;
    margin-left: -0.85rem;
    font-size: 1.6842rem;
  }
}
