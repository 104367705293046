.m-nav {
  &__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
  }

  &__list-item {
    @include font-smoothing;
    font-weight: $font-weight-bold;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: $behemot-letter-spacing;
  }

  &__item {
    display: block;
    padding: 5px 10px;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__dropdown-list {
    font-weight: normal;
  }

  &__dropdown-item {
    &.is-active {
      background-color: $dropdown-link-hover-bg;
    }
  }
}
