// Checkout module doesn't follow BEM guidelines because of the limited control over markup and shared code for all themes.

.m-checkout {
  @media screen and (min-width: $screen-xlg-min) {
    padding-right: floor($grid-gutter-width * 2);
    padding-left: floor($grid-gutter-width * 2);
  }

  &__cols {
    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: $screen-md-min) {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  .section-subheading {
    @media screen and (min-width: $screen-sm-min) {
      font-size: 2.5263rem;

      .back-to-menu {
        font-size: 1.5rem;
        letter-spacing: normal;
      }
    }

    @media screen and (min-width: $screen-lg-min) {
      font-size: 3rem;
    }
  }

  .column-form {
    margin-right: $grid-gutter-width;
    width: 100%;
    order: 2;

    @media screen and (min-width: $screen-md-min) {
      margin-right: floor($grid-gutter-width * 2);
      width: 57%;
      flex: 1 1 57%;
    }

    @media screen and (min-width: $screen-xlg-min) {
      margin-right: floor($grid-gutter-width * 3);
    }
  }

  .column-summary {
    width: 100%;
    order: 1;

    @media screen and (min-width: $screen-xs-min) {
      padding: ceil($behemot-spacing-padding / 1.5);
      box-shadow: $behemot-shadow;
    }

    @media screen and (min-width: $screen-md-min) {
      width: 43%;
      max-width: 668px;
      flex: 1 1 43%;
      order: 3;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding: $behemot-spacing-padding;
    }

    .section-subheading {
      @media screen and (min-width: $screen-xs-min) {
        margin-top: 0;
      }

      @media screen and (min-width: $screen-sm-min) {
        font-size: 2.5263rem;
      }

      @media screen and (min-width: $screen-lg-min) {
        font-size: 4rem;
      }
    }

    .highlight {
      text-transform: uppercase;
      text-align: right;
      font-size: 1.5rem;

      @media screen and (min-width: $screen-sm-min) {
        font-size: 2rem;
      }

      @media screen and (min-width: $screen-lg-min) {
        font-size: 2.5263rem;
      }
    }

    .total-value {
      text-align: right;
      white-space: nowrap;
    }
  }

  .summary-view {
    margin-bottom: 0;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 1.1579rem;
    }

    th {
      text-transform: uppercase;
      letter-spacing: $behemot-letter-spacing;
    }

    th,
    td {
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }

    .subtotal,
    .total-value,
    th:nth-child(3),
    td:nth-child(3) {
      padding-right: 0;
      text-align: right;
    }
  }

  abbr[title=required] {
    margin-right: 5px;
    margin-left: 5px;
    color: $brand-danger;
  }

  section {
    margin-bottom: $base-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-actions {
    [type=submit] {
      @include button-size($btn-padding-large-vertical, $btn-padding-large-horizontal, 2.6316rem, $btn-line-height-base, $btn-border-radius-base);
      display: block;
      width: 100%;
      margin-top: $base-spacing;
    }
  }
}
