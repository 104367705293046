.m-list-nav {
  &__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    text-align: left;
    counter-reset: behemot-list-counter;
  }

  &__list-item {
    @include font-smoothing;
    position: relative;
    display: inline-block;
    margin: 0.2rem 0;
    padding-left: 2.63158rem;
    font-size: 1.2632rem;
    font-weight: $font-weight-bold;
    letter-spacing: $behemot-letter-spacing;
    text-transform: uppercase;
    transition: transform $base-duration;
    transform-origin: right;

    @media screen and (max-width: $screen-xs-max) {
      margin-right: 1.2632rem;
    }

    @media screen and (min-width: $screen-sm-min) {
      display: block;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    &.is-active {
      color: inherit;
    }
  }

  &__item {
    color: inherit;
    transition: transform $base-duration; // additional support for components using @extend
    transform-origin: right; // additional support for components using @extend

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:before {
      position: absolute;
      left: 0;
      counter-increment: behemot-list-counter;
      content: counter(behemot-list-counter, decimal-leading-zero);
    }
  }
}
