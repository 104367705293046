.m-fancy-header {
  &__title {
    position: relative;
    margin: 0 0 45px;
    padding-top: 0.3em;
    font-size: 3rem;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    hyphens: auto;

    @media screen and (min-width: $screen-xs-min) {
      letter-spacing: $behemot-letter-spacing;
    }

    @media screen and (min-width: $screen-sm-min) {
      font-size: 4rem;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-left: 0.7em;
      font-size: 7.5rem;
    }

    &:before {
      @include position(absolute, 0 null null null);
      transform: translateX(-70%);
      z-index: -1;
      font-size: 60%;
      left: 40%;
      content: attr(data-title);

      @media screen and (min-width: $screen-lg-min) {
        left: 1.5em;
        transform: none;
      }
    }

    &:after {
      @media screen and (min-width: $screen-lg-min) {
        display: block;
        font-size: 125%;
        text-align: left;
        content: attr(data-title);
      }
    }

    &--special {
      @media screen and (min-width: $screen-lg-min) {
        text-align: right;
      }

      &:after {
        display: none;
      }
    }

    &--regular {
      padding-left: 0;
      font-size: 4.2105rem;
      text-align: right;

      &:before {
        left: 0.5em;
      }
    }
  }
}
