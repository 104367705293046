// Typography
$font-size-base: 19px;
$line-height-base: 1.5;
$line-height-computed: 60px;

$headings-size-breakpoint: 768px;

$font-size-h1-gfb: 2.5789rem; // ~49px
$font-size-h2-gfb: 2.1053rem; // ~40px
$font-size-h3-gfb: 1.7368rem; // ~33px
$font-size-h4-gfb: 1.2632rem; // ~24px
$font-size-h5-gfb: 1rem; // ~19px
$font-size-h6-gfb: 0.8947rem; // ~17px

$font-size-h1: ($font-size-h1-gfb / 1.2);
$font-size-h2: ($font-size-h2-gfb / 1.2);
$font-size-h3: ($font-size-h3-gfb/ 1.2);
$font-size-h4: ($font-size-h4-gfb / 1.2);
$font-size-h5: ($font-size-h5-gfb / 1.2);
$font-size-h6: ($font-size-h6-gfb / 1.2);

// Dropdown
$dropdown-font-size: 1rem;
$dropdown-link-hover-bg: rgba(#000, 0.04);
$dropdown-link-color: inherit;
$dropdown-link-hover-color: inherit;

// Layout
$grid-gutter-width: 30px;
$grid-gutter-height: 30px;
$container-desktop: (1180px + $grid-gutter-width);
$container-large-desktop: (1570px + $grid-gutter-width);
$screen-md-min: 1200px;
$screen-lg-min: 1600px;
$screen-xlg-min: 1920px;
$border-radius-base: 0;
$caret-width-base: 6px;
$cart-width: 700px;
$padding-base-vertical: 15px;
$padding-base-horizontal: 30px;
$grid-float-breakpoint: 992px;
$mobile-navigation-breakpoint-max: $screen-lg-min - 1;
$behemot-shadow: 0 0 21px rgba(#141515, 0.4);
$behemot-letter-spacing: 0.15em;
$behemot-spacing-padding: 40px;

// Map
$m-map-height: 460px;

// Modal
$modal-inner-padding: 15px 20px;
$modal-title-padding: $modal-inner-padding;
$modal-footer-padding: $modal-inner-padding;
$modal-lg: 1000px;
$modal-md: 700px;

// Flex layout
$m-flex-layout-aside-max-width: 415px;

// Table
$table-border-color: transparent;
$table-cell-padding: 16px 8px;

// Floating Action Button
$m-floating-action-btn-size: 70px;
$m-floating-action-btn-font-size: 35px;

// Form
$input-border-radius: 0;
$input-border: rgba(#000, 0.1);
$input-border-shorthand: 3px solid $input-border;
$input-box-shadow: none;
$input-height-base: 72px;

// Checkbox
$m-checkbox-size: 32px, 32px;

// Radio
$m-radio-size: 32px, 32px;

// Buttons
$btn-default-bg: transparent;
$btn-text-transform: uppercase;
$btn-border-radius-large: 0;
$btn-font-size-large: inherit;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;
$btn-font-weight: $font-weight-bold;
$btn-padding-base-vertical: 15px;
$btn-padding-base-horizontal: 30px;
$btn-padding-large-vertical: $btn-padding-base-vertical;
$btn-padding-large-horizontal: 55px;
$btn-font-size-base: 1.2632rem;
$btn-font-size-large: $btn-font-size-base;
$m-button-default-selectors: ".cart-button";
$m-button-primary-selectors: ".btn-checkout", ".button";

// Navigation
$m-nav-toggle-padding: 3px;

// List module
$m-list-item-border-top: 0;
$m-list-title-font-size: 5.2632rem;

// Item module
$m-item-font-small-size: 1rem;
$m-item-gutter-base: ceil($grid-gutter-width / 2);

// Group nav module
$m-group-nav-link-padding: 0;

// Card
$card-background: none;
$card-box-shadow: none;
$card-margin-bottom: 0;
$card-padding: 0;

// Promo section
$m-promo-section-font-size: 3rem;
$m-promo-section-text-transform: uppercase;
$m-promo-section-font-weight: $font-weight-bold;

// Restaurant Selection
$m-restaurants-form-wrapper-max-width: 1120px;

// Shared dependencies
@import 'base/shared-themes-dependencies';

// Custom styles
@import 'base/shared-essentials';
@import 'base/shared-themes';
@import 'modules/m-media-cover';
@import 'modules/m-buttons';
@import 'modules/m-floating-action';

// Behemot
@import 'modules/behemot/m-header';
@import 'modules/behemot/m-brand';
@import 'modules/behemot/m-nav';
@import 'modules/behemot/m-icon-group';
@import 'modules/behemot/m-nav-toggle';
@import 'modules/behemot/m-hero';
@import 'modules/behemot/m-page-header';
@import 'modules/behemot/m-fancy-header';
@import 'modules/behemot/m-section';
@import 'modules/behemot/m-steps';
@import 'modules/behemot/m-indicators';
@import 'modules/behemot/m-testimonials';
@import 'modules/behemot/m-teasers';
@import 'modules/behemot/m-list-nav';
@import 'modules/behemot/m-info-box';
@import 'modules/behemot/m-checkout';
@import 'modules/behemot/m-footer';

* {
  color: inherit; // Inherit color for all elements to improve color settings from theme.scss.liquid
}

html {
  font-size: $font-size-base;
}

body {
  font-size: 1rem;
}

img {
  @include img-responsive();
}

.stars {
  img {
    display: inline;
  }
}

button,
a {
  transition: color, background-color, opacity, border-color;
  transition-duration: $base-duration;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
  letter-spacing: $behemot-letter-spacing;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: floor($line-height-computed / 1.5);
}

.icon-ok {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background-color: $brand-success;

  &:before {
    vertical-align: baseline;
  }
}

.icon-user-text {
  @media screen and (min-width: $grid-float-breakpoint-max) {
    display: none !important; // override helper classes from shared partial
  }
}

.dropdown-menu {
  animation: fadeIn $base-duration;
}

.btn-default,
.btn-primary {
  @include font-smoothing;
}

.btn-xlg {
  @include button-size($btn-padding-base-vertical, 70px, $btn-font-size-base, $btn-line-height-base, $btn-border-radius-base);
}

.btn-creator,
.add-button {
  @include button-size(12px, $btn-padding-base-horizontal, 1rem, 1.1, $btn-border-radius-base);
  text-transform: none;
}

.btn-link {
  &--tight {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.m-container-fixed {
  @include container-fixed($behemot-spacing-padding);
  max-width: 1920px;
}

.m-container-content {
  @extend .m-container-fixed;

  @media screen and (min-width: $grid-float-breakpoint) {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.m-container-special {
  @extend .m-container-fixed;

  @media screen and (min-width: $grid-float-breakpoint) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media screen and (min-width: $screen-md-min) {
    padding-right: 150px;
    padding-left: 150px;
  }
}

.m-promo-code {
  &,
  &[readonly],
  &[disabled] {
    display: inline-block;
    padding: $btn-padding-base-vertical $btn-padding-base-horizontal;
    border: none;
    background: #fff;
    box-shadow: none;
    cursor: text;
    font-weight: $btn-font-weight;
    text-align: center;
  }
}

.m-quote-sign {
  display: block;
  text-align: center;
  height: 100px;

  &:before {
    position: relative;
    top: -30px;
    font-size: 300px;
    line-height: 1;
    font-weight: $font-weight-bold;
    content:"\201C";
  }
}

.contact { // contact page
  .opening-hours {
    display: flex;
    flex-wrap: wrap;
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);

    &.text-centered {
      text-align: left;
    }

    .opening-hours__group {
      padding-right: floor($grid-gutter-width / 2);
      padding-left: floor($grid-gutter-width / 2);
      flex: 1 1 250px;
    }
  }

  .street-view__item {
    width: 100%;
  }
}

.content-content {
  > .form-inline {
    margin-bottom: $base-spacing;
  }
}

.popover-inner-title {
  margin-top: 0;
}

#svg-theme-icon-clock {
  * {
    fill: none;
    stroke: currentColor;
  }
}

// Action link
.m-action-link {
  color: inherit;
  font-weight: $font-weight-bold;
  font-size: 1.1579rem;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// Modal
[class="modal-dialog"] {
  @media screen and (min-width: $screen-md-min) {
    width: ($modal-lg - 100);
  }
}

.form-control {
  padding-right: 20px;
  padding-left: 20px;

  &.m-select__select {
    padding-right: 40px;
  }
}

// Buttons
.btn {
  @media (max-width: $screen-xs-max) {
    font-size: 1rem;
  }
}

// List module
.m-list {
  &__title {
    font-weight: $font-weight-bold;
    letter-spacing: $behemot-letter-spacing;
    text-transform: uppercase;
  }
}

// Group nav module
.m-group-nav {
  padding: 20px $behemot-spacing-padding;
  box-shadow: $behemot-shadow;

  .m-group-nav__list {
    @extend .m-list-nav__list;
  }

  .m-group-nav__list-item {
    @extend .m-list-nav__list-item;
  }

  .m-group-nav__link {
    @extend .m-list-nav__item;
  }

  .m-group-nav__title {
    &[data-title] {
      @extend .m-fancy-header__title;
    }
  }

  &--aside {
    padding: $behemot-spacing-padding;

    .m-group-nav__title {
      &[data-title] {
        @extend .m-fancy-header__title--regular;
      }
    }
  }

  &--horizontal {
    display: flex;
    align-items: center;

    .m-group-nav__title {
      &[data-title] {
        @extend .m-fancy-header__title--special;
        margin-bottom: 0;
        padding-left: 0;
        font-size: 5rem;
        text-align: left;
      }
    }

    .m-group-nav__list {
      margin-left: auto;
    }

    .m-group-nav__list-item {
      display: inline-block;
    }

    .m-group-nav__link {
      padding-right: $grid-gutter-width;
    }
  }

  &--tabs {
    padding: 0;
    box-shadow: unset;

    .m-group-nav__list-item {
      margin: 0;
      padding: 0;
    }

    .m-group-nav__link {
      &:before {
        display: none;
      }
    }
  }
}

// Item module
.m-item {
  &__price {
    font-weight: $font-weight-bold;
  }
}

// Cart
.cart {
  .delivery {
    .control-label {
      margin-bottom: 10px;
    }

    .radio label,
    .checkbox label {
      min-height: 40px;
    }
  }
}
