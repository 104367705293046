.m-icon-group {
  &__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    margin-left: -10px;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-left: -15px;
    }
  }

  &__list-item {
    @include font-smoothing;
    font-weight: $font-weight-bold;
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
    text-transform: uppercase;
    letter-spacing: $behemot-letter-spacing;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-right: 15px;
      padding-left: 15px;
    }

    svg {
      margin-right: -5px;
      vertical-align: top;
    }

    .icon-user {
      font-size: 1.2em;
    }
  }

  .m-popover {
    p,
    .m-hr,
    .opening-hours__group,
    .popover-inner-title {
      margin-bottom: floor($base-spacing / 2);
    }

    .opening-hours,
    .m-hr {
      margin-top: floor($base-spacing / 2);
    }
  }
}
