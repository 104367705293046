.m-page-header {
  @include clearfix;
  margin: 0 0 50px;
  text-align: center;

  @media screen and (max-width: $grid-float-breakpoint-max) {
    word-wrap: break-word; /* simple fallback for `hyphens` */
    hyphens: auto;
  }

  &__title {
    margin: 0 0 0.25em;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    letter-spacing: $behemot-letter-spacing;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 3rem;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      font-size: 5.2631rem;
    }
  }

  &__description {
    font-size: 1.2632rem;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 1.5789rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Add compatibility with shared modules (apply Behemot header style)
.content-header {
  @extend .m-page-header;

  h1 {
    @extend .m-page-header__title;
  }
}
