.m-info-box {
  &__area {
    display: flex;
    font-size: 1.1579rem;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      flex-wrap: wrap;
    }
  }

  &__box {
    margin-bottom: $base-spacing;
    flex: 1 1 auto;
    line-height: 2;

    @media screen and (min-width: $grid-float-breakpoint) {
      text-align: center;
      max-width: 33%;
    }

    p {
      margin-bottom: 0;
    }

    &:first-child {
      @media screen and (min-width: $grid-float-breakpoint) {
        text-align: right;
      }
    }

    &:last-child {
      @media screen and (min-width: $grid-float-breakpoint) {
        text-align: left;
      }
    }

    &--hours {
      line-height: 2.5;
    }

    &--icon {
      padding-left: $grid-gutter-width;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: none;
      }

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
      }

      .svg-icon-clock {
        max-width: 22vw;
        max-height: 22vw;
      }
    }

    &--info {
      p {
        margin-bottom: $base-spacing;
      }
    }
  }
}
